import { boot } from 'quasar/wrappers'
import axios from 'axios'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const PANEL_URL = process.env.VUE_APP_API_PANEL;
const AUTH_URL = process.env.VUE_APP_API_AUTH;
const BROADCAST_URL = process.env.VUE_APP_API_BROADCAST;
const WHATSAPP_URL = process.env.VUE_APP_API_WHATSAPP;
const TELEGRAM_URL = process.env.VUE_APP_API_TELEGRAM;
const LIVE_URL = process.env.VUE_APP_API_LIVE_DEV;
const INTEGRATIONS_URL = process.env.VUE_APP_API_INTEGRATIONS;
const REPORTS_URL = process.env.VUE_APP_API_REPORTS;
const CDN_URL = process.env.VUE_APP_API_CDN;
const GLOBALBOT_WHATSAPP =  process.env.VUE_APP_GLOBALBOT_WHATSAPP

export default boot(({ app }) => {
  app.config.globalProperties.$axios = { ...axios.create({}) }
  app.config.globalProperties.$axios.defaults.baseURL = PANEL_URL
  // Default object
  app.config.globalProperties.$API = {}
  // API Auth
  app.config.globalProperties.$API.auth = { ...axios.create({}) }
  app.config.globalProperties.$API.auth.defaults.baseURL = AUTH_URL
  // API Panel
  app.config.globalProperties.$API.panel = { ...axios.create({}) }
  app.config.globalProperties.$API.panel.defaults.baseURL = PANEL_URL
  // API Broadcast
  app.config.globalProperties.$API.broadcast = { ...axios.create({}) }
  app.config.globalProperties.$API.broadcast.defaults.baseURL = BROADCAST_URL
  // API WhatsApp
  app.config.globalProperties.$API.whatsapp = { ...axios.create({}) }
  app.config.globalProperties.$API.whatsapp.defaults.baseURL = WHATSAPP_URL
  // API Telegram
  app.config.globalProperties.$API.telegram = { ...axios.create({}) }
  app.config.globalProperties.$API.telegram.defaults.baseURL = TELEGRAM_URL
  // API Live
  app.config.globalProperties.$API.live = { ...axios.create({}) }
  app.config.globalProperties.$API.live.defaults.baseURL = LIVE_URL
  // API Integrations
  app.config.globalProperties.$API.integrations = { ...axios.create({}) }
  app.config.globalProperties.$API.integrations.defaults.baseURL = INTEGRATIONS_URL
  // API Reports
  app.config.globalProperties.$API.reports = { ...axios.create({}) }
  app.config.globalProperties.$API.reports.defaults.baseURL = REPORTS_URL
  // API CDN
  app.config.globalProperties.$API.cdn = { ...axios.create({}) }
  app.config.globalProperties.$API.cdn.defaults.baseURL = CDN_URL
  // API GLOBALBOT WhatsApp Broker
  app.config.globalProperties.$API.globalbotWhatsapp = { ...axios.create({}) }
  app.config.globalProperties.$API.globalbotWhatsapp.defaults.baseURL = GLOBALBOT_WHATSAPP

})
