import { boot } from 'quasar/wrappers'
import Socket from 'socket.io-client'
import { setCookie, getCookie, eraseCookie } from '../utils/Cookie'

import moment from 'moment';

const AUTH_SOCKET = process.env.VUE_APP_SOCKET_AUTH;
const LIVE_SOCKET = process.env.VUE_APP_SOCKET_LIVE;

export default boot(({ app, store, router }) => {
  app.config.globalProperties.$SOCKET = {}
  app.config.globalProperties.$SOCKET.auth = Socket(AUTH_SOCKET, {
    autoConnect: false,
    query: {},
    transports: ['websocket', 'polling']
  })
  app.config.globalProperties.$SOCKET.auth.on('connection', () => {
    console.info('Auth Connected')
  })
  app.config.globalProperties.$SOCKET.auth.on('connect', () => {
    console.info('Auth Connected')
  })
  app.config.globalProperties.$SOCKET.auth.on('disconnect', (event) => {
    if (store.state.main.lostConnection.isActive === false) {
      store.commit('main/enableWarningLostConnection')
    }
  })
  app.config.globalProperties.$SOCKET.auth.on('reconnect', () => {
    if (store.state.main.lostConnection.isActive === true) {
      store.commit('main/disableWarningLostConnection')
      console.info('reconnect - auth')
    }
  })
  app.config.globalProperties.$SOCKET.auth.on('alarm', async (payload) => {
    try {
      const defaultDate = new Date(payload.controller.end)
      defaultDate.setMinutes(defaultDate.getMinutes() + payload.tolerance)
      const toleranceDate = moment.utc(defaultDate.getTime() - new Date().getTime())
      store.commit('main/setToleranceAlarm', toleranceDate)
      const counter = setInterval(() => {
        store.commit('main/setToleranceAlarm', toleranceDate.subtract(1, 's'))
        store.commit('main/setDisplayAlarm', toleranceDate.format('HH:mm:ss'))
        if (toleranceDate.format('HH:mm:ss') === '00:00:00') {
          clearInterval(counter)
        }
      }, 1000)
      store.commit('main/enableAlarm')
    } catch (e) {
      console.error(e)
    }
  })

  // CONNECT TO ZION
  let reconnect = false
  app.config.globalProperties.$SOCKET.zion = Socket(LIVE_SOCKET, {
    autoConnect: false,
    query: {},
    transports: ['websocket', 'polling'],
    secure: true,
    reconnect: true,
    pingInterval: 25000,
    pingTimeout: 20000,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000
  })
  app.config.globalProperties.$SOCKET.zion.on('connection', () => {
    console.info('Zion Connected')
  })
  app.config.globalProperties.$SOCKET.zion.on('reconnect', () => {
    if (store.state.main.lostConnection.isActive === true) {
      store.commit('main/disableWarningLostConnection')
    }
    console.info('reconnect - zion')
    reconnect = true
  })
  app.config.globalProperties.$SOCKET.zion.on('connect', () => {
    if (reconnect) {
      reconnect = false
      app.config.globalProperties.$SOCKET.zion.emit('changeStatus', store.state.main.zion.status)
      if (router.currentRoute && router.currentRoute.value && router.currentRoute.value.path && router.currentRoute.value.path.split('/')[1] === 'live' && router.currentRoute.value.params._id) {
        app.config.globalProperties.$SOCKET.zion.emit('enterRoom', { roomId: router.currentRoute.value.params._id })
      }
    } else {
      let statusCookie = getCookie('livestatus')
      app.config.globalProperties.$SOCKET.zion.emit('changeStatus', statusCookie && statusCookie !== '' ? Number(statusCookie) : -5)
    }
  })
  app.config.globalProperties.$SOCKET.zion.on('changeStatus', e => {
    if (e.hasOwnProperty('status')) {
      store.commit('main/setZion', { status: e.status })
    }
  })
  app.config.globalProperties.$SOCKET.zion.on('disconnect', event => {
    console.log('disconnect - Zion')
    if (store.state.main.lostConnection.isActive === false) {
      store.commit('main/enableWarningLostConnection')
    }
  })
})
